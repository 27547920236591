import React from 'react';

const DocDraft = () => {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Document Drafting Page</h2>
      <p>This is a placeholder for the document drafting page. Here you can draft and manage legal documents.</p>
    </div>
  );
};

export default DocDraft;
