import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LawyerRegistrationForm from './components/LawyerRegistrationForm';
import StateSelection from './components/StateSelection';
import DistrictSelection from './components/DistrictSelection';
import ComplexSelection from './components/ComplexSelection';
import HighCourtSelection from './components/HighCourtSelection';
import CATSelection from './components/CATSelection';
import ReviewSelection from './components/ReviewSelection';
import Dashboard from './components/Dashboard';
import AIChat from './components/AIChat';
import Profile from './components/Profile';
import Calendar from './components/Calendar';
import Team from './components/Team';
import DocDraft from './components/DocDraft';
import TopBar from './components/TopBar';
import Home from './components/Home'; // Import the Home component
import Login from './components/Login'; // Import the Login component
import UpcomingTodos from './components/UpcomingToDos'; 
import AddCasePage from './components/AddCasePage';
import './App.css';
import { UserProvider } from './UserContext'; // Import UserProvider

function App() {
  const [userId, setUserId] = useState(() => {
    // Get the userId from local storage if it exists
    return localStorage.getItem('userId') || null;
  });

  const [userData, setUserData] = useState({
    advocateName: '',
    email: '',
    mobile: '',
    licenseNumber: '',
    selectedStates: [],
    selectedDistricts: {},
    selectedComplexes: {},
    selectedHCStates: {},
    selectedCATBench: ''
  });

  const location = useLocation();

  // Effect to update local storage when userId changes
  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);  // Save userId to local storage
    } else {
      localStorage.removeItem('userId');  // Remove userId from local storage if null
    }
  }, [userId]);
  
  // Specify the routes where TopBar should NOT be shown
  const noTopBarRoutes = [
    '/',
    '/login',
    '/register',
    '/state-selection',
    '/district-selection',
    '/complex-selection',
    '/highcourt-selection',
    '/cat-selection',
    '/review-selection'
  ];

  const shouldShowTopBar = !noTopBarRoutes.includes(location.pathname);

  return (
    <div className="App">
      {shouldShowTopBar && <TopBar />}
      <Routes>
        {/* Home page as the main entry */}
        <Route path="/" element={<Home />} />
        
        {/* Registration and Login */}
        <Route path="/register" element={<LawyerRegistrationForm setUserData={setUserData} />} />
        <Route path="/login" element={<Login setUserId={setUserId} />} />
        
        {/* Selection Process */}
        <Route path="/state-selection" element={<StateSelection userData={userData} setUserData={setUserData} />} />
        <Route path="/district-selection" element={<DistrictSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/complex-selection" element={<ComplexSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/highcourt-selection" element={<HighCourtSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/cat-selection" element={<CATSelection userData={userData} setUserData={setUserData} />} />
        <Route path="/review-selection" element={<ReviewSelection userData={userData} />} />

        {/* App features after login */}
        <Route path="/dashboard" element={<Dashboard userId={userId} />} />
        <Route path="/ai-chat" element={<AIChat userId={userId} />} />
        <Route path="/profile" element={<Profile userId={userId} />} />
        <Route path="/calendar" element={<Calendar userId={userId} />} />
        <Route path="/team" element={<Team userId={userId} />} />
        <Route path="/docdraft" element={<DocDraft userId={userId} />} />
        <Route path="/upcoming-todos" element={<UpcomingTodos userId={userId}/>} /> 
        <Route path="/add-case" element={<AddCasePage />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  );
}

export default AppWrapper;
