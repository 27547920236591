import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import lawyerBackground from '../assets/lawyer_background.webp';
import lawCalendar from '../assets/lawcalendar.webp';
import dataSecurity from '../assets/data.webp';
import invoice from '../assets/invoice.webp';
import playStoreIcon from '../assets/play-store.png';
import appStoreIcon from '../assets/app-store.png';

function Home() {
    const navigate = useNavigate();
    const slides = [
        {
            img: lawyerBackground,
            title: "Your Digital Court Diary",
            description: "High Court, District Courts, CAT, NCLAT, Consumer Court, etc."
        },
        {
            img: lawCalendar,
            title: "Notifications and AI Tips",
            description: "Next Dates, Case History, Orders, and AI Practice Tips"
        },
        {
            img: dataSecurity,
            title: "Robust Security",
            description: "Your data is secure with industry-leading protection"
        },
        {
            img: invoice,
            title: "Manage Billing and Clients",
            description: "Generate Invoices, Track them, Manage New Clients"
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div>
            <header>
                <div className="container header-container">
                    <h1>Welcome to GenLawyers</h1>
                    <p>Your trusted legal assistant</p>
                </div>
            </header>

            <div className="slideshow-container">
                {slides.map((slide, index) => (
                    <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                        <img src={slide.img} alt={slide.title} />
                        <div className="slide-text">
                            <h1>{slide.title}</h1>
                            <p>{slide.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <section className="auth-section">
                <p className="auth-section-text-above">Create your Virtual Chamber</p>
                <div className="auth-buttons-container">
                    <button
                        className="auth-button"
                        onClick={() => {
                            console.log('Navigating to /register');
                            setTimeout(() => navigate('/register'), 0);
                        }}
                    >
                        Register
                    </button>
                    <button
                        className="auth-button"
                        onClick={() => {
                            console.log('Navigating to /login');
                            setTimeout(() => navigate('/login'), 0);
                        }}
                    >
                        Login
                    </button>
                </div>
                <p className="auth-section-text-below">Only Register if You are an Advocate</p>
            </section>

            <section className="section-dark">
                <div className="container">
                    <h2>Why GenLawyers?</h2>
                    <p>Manage your legal practice with our state-of-the-art digital solutions.</p>
                    <div className="platform-buttons-container">
                        <button className="platform-button" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.genlawyers'}>
                            <img src={playStoreIcon} alt="Play Store" />
                        </button>
                        <button className="platform-button" onClick={() => window.location.href = 'https://apps.apple.com/us/app/genlawyers/id1234567890'}>
                            <img src={appStoreIcon} alt="App Store" />
                        </button>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <p>&copy; 2024 GenLawyers (c) All Rights Reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default Home;
