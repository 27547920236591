import React from 'react';
import ProgressBar from './ProgressBar';
import './styles.css';

function MultiStepFormLayout({ step, totalSteps, onNext, onBack, children }) {
  return (
    <div className="multi-step-form-layout">
      <header className="constant-header">
        <h1>Create Your Virtual Chamber</h1>
        <p>Your Court Calendar and Personal Assistant</p>
      </header>

      <div className="form-step-header">
        <h2>Step {step} of {totalSteps}</h2>
        <ProgressBar currentStep={step} totalSteps={totalSteps} />
      </div>

      <main className="form-content">
        <div className="content-container">
          {children}
        </div>
      </main>

      {/* Button container */}
      <div className="button-container">
        {step > 1 && (
          <button className="back-button" onClick={onBack}>
            <i className="fas fa-arrow-left"></i> Back
          </button>
        )}
        <button 
        className="next-button" 
        onClick={(e) => {
          e.preventDefault(); // Prevent default form behavior
          onNext(); // Call the onNext prop
        }}
      >
        {step < totalSteps ? (
          <>Next <i className="fas fa-arrow-right"></i></>
        ) : (
          <>Finish</>
        )}
      </button>
      </div>

      <div style={{ marginTop: '60px' }}></div>

      {/* Footer section */}
      <footer className="form-footer">
        <p>© 2024 Prodigy Legal LLP. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default MultiStepFormLayout;
