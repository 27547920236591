import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import './TopBar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { auth } from '../firebaseConfig';

function TopBar() {
    const [isMobile, setIsMobile] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Use navigate to go to the login page
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const isActive = (path) => location.pathname === path ? 'active' : '';

    return (
        <div className="top-bar">
            <h1 className="dashboard-title">GenLawyers</h1>
            {!isMobile ? (
                <nav className="top-nav">
                    <Link to="/dashboard" className={`nav-link ${isActive('/dashboard')}`}>Dashboard</Link>
                    <Link to="/ai-chat" className={`nav-link ${isActive('/ai-chat')}`}>AI Chat</Link>
                    <Link to="/profile" className={`nav-link ${isActive('/profile')}`}>Profile</Link>
                    <Link to="/calendar" className={`nav-link ${isActive('/calendar')}`}>Calendar</Link>
                    <Link to="/team" className={`nav-link ${isActive('/team')}`}>Team</Link>
                    <Link to="/docdraft" className={`nav-link ${isActive('/docdraft')}`}>DocDraft</Link>
                    <Link to="/upcoming-todos" className={`nav-link ${isActive('/upcoming-todos')}`}>Upcoming</Link>
                    <Link to="/add-case" className={`nav-link ${isActive('/add-case')}`}>Add Case</Link> {/* New Link for Add Case */}
                    <button onClick={handleLogout} className="nav-link">Logout</button>
                </nav>
            ) : (
                <div className="hamburger-menu" onClick={toggleDrawer}>
                    <MenuIcon style={{ color: 'white' }} />
                </div>
            )}
            {isMobile && drawerOpen && (
                <div className="drawer">
                    <div className="drawer-header">
                        <CloseIcon onClick={toggleDrawer} style={{ cursor: 'pointer' }} />
                    </div>
                    <nav className="drawer-nav">
                        <Link to="/dashboard" className={`drawer-link ${isActive('/dashboard')}`} onClick={toggleDrawer}>Dashboard</Link>
                        <Link to="/ai-chat" className={`drawer-link ${isActive('/ai-chat')}`} onClick={toggleDrawer}>AI Chat</Link>
                        <Link to="/profile" className={`drawer-link ${isActive('/profile')}`} onClick={toggleDrawer}>Profile</Link>
                        <Link to="/calendar" className={`drawer-link ${isActive('/calendar')}`} onClick={toggleDrawer}>Calendar</Link>
                        <Link to="/team" className={`drawer-link ${isActive('/team')}`} onClick={toggleDrawer}>Team</Link>
                        <Link to="/docdraft" className={`drawer-link ${isActive('/docdraft')}`} onClick={toggleDrawer}>DocDraft</Link>
                        <Link to="/upcoming-todos" className={`drawer-link ${isActive('/upcoming-todos')}`} onClick={toggleDrawer}>Upcoming</Link>
                        <Link to="/add-case" className={`drawer-link ${isActive('/add-case')}`} onClick={toggleDrawer}>Add Case</Link> {/* New Link for Add Case */}
                        <button onClick={() => { handleLogout(); toggleDrawer(); }} className="drawer-link">Logout</button>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default TopBar;
