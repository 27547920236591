import React, { useState } from 'react';
import { TextField, MenuItem, Button, Box, Typography, Alert } from '@mui/material';
import { auth } from '../firebaseConfig'; // Import Firebase Auth
import axios from 'axios';

const AddCasePage = () => {
  const [cnrNumber, setCnrNumber] = useState('');
  const [courtType, setCourtType] = useState('DistrictCourt');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const courtTypes = ['DistrictCourt', 'HighCourt'];

  // Function to handle case submission and API request
  const handleAddCase = async () => {
    const userId = auth.currentUser?.uid; // Get logged-in user's ID

    if (!userId) {
      alert('User is not logged in.');
      return;
    }

    if (cnrNumber.trim() === '') {
      alert('Please enter a valid CNR Number.');
      return;
    }

    try {
      // Make the API call to add the case using Axios
      const response = await axios.post('https://genlawyers.com/single_cnr', {
        user_id: userId, // Send the logged-in user ID
        court_type: courtType,
        cnr_number: cnrNumber,
      });

      if (response.status === 200) {
        setShowSuccessMessage(true); // Show success message
        setCnrNumber(''); // Reset form fields
      } else {
        alert('Error adding case, please try again.');
      }
    } catch (error) {
      console.error('Error adding case:', error);
      alert('Error adding case, please check your connection.');
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Add New Case
      </Typography>

      <Typography variant="body1" gutterBottom>
        Enter CNR Number:
      </Typography>
      <TextField
        fullWidth
        value={cnrNumber}
        onChange={(e) => setCnrNumber(e.target.value)}
        label="CNR Number"
        variant="outlined"
        margin="normal"
      />

      <Typography variant="body1" gutterBottom>
        Select Court Type:
      </Typography>
      <TextField
        select
        fullWidth
        value={courtType}
        onChange={(e) => setCourtType(e.target.value)}
        label="Court Type"
        variant="outlined"
        margin="normal"
      >
        {courtTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddCase}
        sx={{ mt: 2 }}
      >
        Add Case
      </Button>

      {showSuccessMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Case added successfully! It will appear in a few minutes.
        </Alert>
      )}
    </Box>
  );
};

export default AddCasePage;
