import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { db } from '../firebaseConfig'; // Firestore configuration
import { doc, getDoc } from 'firebase/firestore'; // For fetching Firestore details
import lawLogo from '../assets/law_logo.png'; // Import the law logo (make sure you have this image)

async function generateInvoicePDF(invoiceData, userId, petitioner, respondent) {
  // Fetch user details from Firestore
  const userDocRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userDocRef);


  if (userSnap.exists()) {
    const userData = userSnap.data();

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Embed fonts
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold); // For bold text

    // Add a blank page to the document
    const page = pdfDoc.addPage([600, 700]);

    // Optionally add a logo in light color (background)
    if (lawLogo) {
      const logoImage = await pdfDoc.embedPng(lawLogo); // Assuming you have a PNG law logo
      const logoDims = logoImage.scale(0.5); // Scale the image size as needed
      page.drawImage(logoImage, {
        x: page.getWidth() / 2 - logoDims.width / 2, // Center the logo
        y: 600, // Adjust Y-position as needed
        width: logoDims.width,
        height: logoDims.height,
        opacity: 0.15, // Make it light-colored
      });
    }

    // Center "Law Offices of <Advocate Name>"
    const lawOfficeTitle = `Law Offices of ${userData.advocateName}`;
    const textWidth = timesRomanBoldFont.widthOfTextAtSize(lawOfficeTitle, 20);
    const centeredX = (page.getWidth() - textWidth) / 2;
    page.drawText(lawOfficeTitle, {
      x: centeredX,
      y: 650,
      size: 20,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    // Add advocate details
    let detailsYPosition = 600;
    const lineSpacing = 20;

    page.drawText(`Advocate: ${userData.advocateName}`, {
      x: 50,
      y: detailsYPosition,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(`Bar ID: ${userData.licenseNumber}`, {
      x: 50,
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(`Email: ${userData.email}`, {
      x: 50,
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(`Mobile: ${userData.mobile}`, {
      x: 50,
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });


    // Add Petitioner and Respondent
    page.drawText(`Petitioner Advocate: ${petitioner || 'N/A'}`, {
      x: 50,
      y: detailsYPosition -= lineSpacing * 2,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(`Respondent Advocate: ${respondent || 'N/A'}`, {
      x: 50,
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    // Add invoice details
    page.drawText(`Invoice Details:`, {
      x: 50,
      y: detailsYPosition -= lineSpacing * 2,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    // Invoice table header
    const columnXPositions = [50, 200, 350]; // Define X positions for the columns
    page.drawText('Amount', {
      x: columnXPositions[0],
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Description', {
      x: columnXPositions[1],
      y: detailsYPosition,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Date', {
      x: columnXPositions[2],
      y: detailsYPosition,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    // Invoice table data
    page.drawText(`${invoiceData.amount}`, {
      x: columnXPositions[0],
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });
    page.drawText(`${invoiceData.description}`, {
      x: columnXPositions[1],
      y: detailsYPosition,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });
    page.drawText(`${invoiceData.date}`, {
      x: columnXPositions[2],
      y: detailsYPosition,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    // Add Bank Account and UPI info
    page.drawText('Bank Account Information:', {
      x: 50,
      y: detailsYPosition -= lineSpacing * 3,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    // Access bank details from userData
const bankDetails = userData.bankDetails || {};

page.drawText(`Account Number: ${bankDetails.accountNumber || 'N/A'}`, {
    x: 50,
    y: detailsYPosition -= lineSpacing,
    size: 12,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
});

page.drawText(`IFSC Code: ${bankDetails.ifscCode || 'N/A'}`, {
    x: 50,
    y: detailsYPosition -= lineSpacing,
    size: 12,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
});

page.drawText(`UPI ID: ${bankDetails.upiId || 'N/A'}`, {
    x: 50,
    y: detailsYPosition -= lineSpacing,
    size: 12,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
});


    // Add payment terms
    page.drawText('Payment Terms:', {
      x: 50,
      y: detailsYPosition -= lineSpacing * 2,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    page.drawText('Delay of more than 1 week will attract an interest rate.', {
      x: 50,
      y: detailsYPosition -= lineSpacing,
      size: 12,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    // Add a thank you message
    page.drawText('Thank you for your business!', {
      x: 50,
      y: detailsYPosition -= lineSpacing * 2,
      size: 12,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });

    // Serialize the PDF to bytes
    const pdfBytes = await pdfDoc.save();

    // Open PDF in a new tab
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL); // Opens the PDF in a new tab
  } else {
    console.log('No such document!');
  }
}

export { generateInvoicePDF };
