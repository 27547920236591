import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Import Firebase config
import { UserContext } from '../UserContext'; // Import the UserContext
import './Login.css'; // Import your custom CSS
import chamberImage from '../assets/chamber.webp'; // Import the chamber image

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext); // Get setUserData from context

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid; // Get the user ID
      setUserData((prev) => ({ ...prev, userId })); // Save userId in context
      navigate('/dashboard'); // Redirect to the dashboard
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className="login-container">
      <img src={chamberImage} alt="Virtual Chamber" className="chamber-image" />
      <h2>Access Your Virtual Chamber</h2>
      <p>Your confirm to be an enrolled Advocate to access and use GenLawyers.</p>
      <form onSubmit={handleLogin}>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button">Login</button>
      </form>
      <div style={{ marginBottom: '20px' }} /> {/* Space below login button */}
      <button onClick={handleGoHome} className="home-button">Go to Home</button>
    </div>
  );
}

export default Login;
