import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // Import auth from firebaseConfig
import { useParams } from 'react-router-dom'; // To get the userId from the route
import './CalendarStyles.css'; // Include the custom CSS

const CaseCalendar = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the user ID from Firebase Auth
  const [cases, setCases] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [casesForSelectedDate, setCasesForSelectedDate] = useState([]);

  // Helper function to parse the nextDate string into a Date object
  const parseDate = (dateString) => {
    if (!dateString) return null;

    const cleanedDateString = dateString
      .replace(/(st|nd|rd|th)/g, '') // Remove ordinal suffixes
      .trim();

    const parsedDate = new Date(cleanedDateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const removeNumbering = (name) => {
    return name.replace(/^1\)\s*/, '').trim();  // Removes "1) " from the start of the string
  };
  
  const createHighCourtCase = (data, id) => {
    const petitionerAdvocate = removeNumbering(data.petitioner_advocate || 'Unknown Petitioner');
    const respondentAdvocate = removeNumbering(data.respondent_advocate || 'Unknown Respondent');
    const nextDate = parseDate(data.case_status?.['Next Hearing Date']);
    return {
      id,
      title: `${petitionerAdvocate} vs ${respondentAdvocate}`,
      nextDate,
      court: data.case_status?.Coram || 'Unknown Court',
    };
  };
  
  const createDistrictCourtCase = (data, id) => {
    const petitioners = (data.Petitioners || ['Unknown Petitioner']).map(removeNumbering);
    const respondents = (data.Respondents || ['Unknown Respondent']).map(removeNumbering);
    const nextDate = parseDate(data.NextHearingDate);
    return {
      id,
      title: `${petitioners.join(', ')} vs ${respondents.join(', ')}`,
      nextDate,
      court: data.CourtNumberAndJudge || 'Unknown Court',
    };
  };
  
  const createCATCase = (data, id) => {
    const petitioner = removeNumbering(data['Petitioner(s)'] || 'Unknown Petitioner');
    const respondent = removeNumbering(data['Respondent(s)'] || 'Unknown Respondent');
    const nextDate = parseDate(data['Case Proceeding Details']?.[0]?.['Next Listing Date']);
    return {
      id,
      title: `${petitioner} vs ${respondent}`,
      nextDate,
      court: data['Case Proceeding Details']?.[0]?.['Court Name'] || 'Unknown Court',
    };
  };
  

  const parseCase = useCallback((doc) => {
    const data = doc.data();
    const caseType = data.caseType;
    const id = doc.id;

    switch (caseType) {
      case 'HighCourt':
        return createHighCourtCase(data, id);
      case 'DistrictCourt':
        return createDistrictCourtCase(data, id);
      case 'CATCases':
        return createCATCase(data, id);
      default:
        return { id, title: 'Unknown Title', court: 'Unknown Court', nextDate: null };
    }
  }, []);

  // Fetch cases from Firestore
  useEffect(() => {
    const fetchCases = async () => {
      const casesRef = collection(db, 'users', userId, 'CaseDetails');
      const querySnapshot = await getDocs(casesRef);
      const casesData = querySnapshot.docs.map(parseCase);
      setCases(casesData);
    };

    if (userId) {
      fetchCases();
    }
  }, [userId, parseCase]);

  // Handle date change and filter cases for the selected date
  const handleDateChange = (date) => {
    setSelectedDate(date);

    const filteredCases = cases.filter((c) => {
      if (c.nextDate instanceof Date) {
        const caseDate = c.nextDate.toLocaleDateString();
        const selectedDateString = date.toLocaleDateString();
        return caseDate === selectedDateString;
      }
      return false;
    });

    setCasesForSelectedDate(filteredCases);
  };

  // Show dots and case names on dates with cases
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const casesForThisDay = cases.filter(
        (c) => c.nextDate instanceof Date && c.nextDate.toLocaleDateString() === date.toLocaleDateString()
      );

      if (casesForThisDay.length > 0) {
        return (
          <div className="calendar-tile-content">
            <div className="dot"></div>
            <ul className="case-list-tile">
              {casesForThisDay.map((c) => (
                <li key={c.id}>{c.title}</li>
              ))}
            </ul>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Calendar Page</h2>
      <p>Click on a date to view cases scheduled for that day.</p>

      {/* Calendar Component */}
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        tileContent={tileContent}
        className="large-calendar"
      />

      {/* List of cases for the selected date */}
      <div style={{ marginTop: '20px' }}>
        <h3>Cases for {selectedDate.toLocaleDateString()}</h3>
        {casesForSelectedDate.length > 0 ? (
          <ul className="case-list">
            {casesForSelectedDate.map((c) => (
              <li key={c.id}>
                <strong>{c.title}</strong>
                <span>Next Hearing Date: {c.nextDate.toLocaleDateString()}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No cases on this date.</p>
        )}
      </div>
    </div>
  );
};

export default CaseCalendar;
