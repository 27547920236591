// src/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1OV0D4k6n4viD5WPZC9-GV3sexmGABD0",
  authDomain: "genlawconnect.firebaseapp.com",
  projectId: "genlawconnect",
  storageBucket: "genlawconnect.appspot.com",
  messagingSenderId: "533164645064",
  appId: "1:533164645064:web:d1132a256c57b238a4e3fd",
  measurementId: "G-GH5WLC00VH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and Auth
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app); 
