import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig'; // Make sure to import auth
import { collection, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import './Dashboard.css';
import { useCallback } from 'react';
import { updateDoc, arrayUnion } from 'firebase/firestore';
import CaseDetail from './CaseDetail'; // Import the CaseDetail component
import TopBar from './TopBar'; // Import TopBar component
import { useUser } from '../UserContext'; // Import the useUser hook
import { useNavigate } from 'react-router-dom';


function Dashboard() {
  const navigate = useNavigate();
  const { userData } = useUser(); // Get userData from context
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCase, setSelectedCase] = useState(null); // State for selected case
  const [isMobileView, setIsMobileView] = useState(false); // State to toggle mobile view
  const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the user ID from Firebase Auth

  useEffect(() => {
    if (!userId) {
      // Redirect to login or handle unauthenticated user
      navigate('/login');
    } else {
      fetchCases(); // Fetch cases if user ID is available
    }
  }, [userId, navigate]); // Dependency on userId


    useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Check if the screen size is small (mobile view)
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Set mobile view if width is <= 768px
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const parseCase = useCallback((document) => {
    const caseType = document.get("caseType");
  
    switch (caseType) {
      case "HighCourt":
        return createHighCourtCase(document);
      case "DistrictCourt":
        return createDistrictCourtCase(document);
      case "CATCases":
        return createCATCase(document);
      default:
        return { id: document.id, title: "Unknown Title", court: "Unknown Court", nextDate: "No Next Hearing Date" };
    }
  }, []);
  
  const fetchCases = useCallback(() => {
    setIsLoading(true);
    const casesRef = collection(db, 'users', userId, 'CaseDetails');
    
    // Use Firestore's onSnapshot to get real-time updates
    const unsubscribe = onSnapshot(casesRef, (querySnapshot) => {
      const casesData = querySnapshot.docs.map(doc => parseCase(doc));
  
      // Get today's date in IST (Indian Standard Time)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset to midnight to compare only dates
  
      // Sort the cases by next hearing date (nearest first)
      const sortedCases = casesData.sort((a, b) => {
        const dateA = parseDate(a.nextDate);
        const dateB = parseDate(b.nextDate);
  
        if (dateA < today && dateB < today) {
          return dateB - dateA;
        }
        if (dateA < today) return 1;
        if (dateB < today) return -1;
  
        return dateA - dateB;
      });
  
      setCases(sortedCases);
      setFilteredCases(sortedCases);
      setIsLoading(false);
    });
  
    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userId, parseCase]);
  
  
  // Helper function to parse the nextDate string into a Date object
const parseDate = (dateString) => {
  if (!dateString) return new Date(8640000000000000); // Set to distant future if no date

  const cleanedDateString = dateString
    .replace(/(st|nd|rd|th)/g, '') // Remove ordinal suffixes
    .trim();

  // Try to parse the date using different formats
  const parsedDate = new Date(cleanedDateString);

  // If the date is invalid, return a distant future date
  return isNaN(parsedDate) ? new Date(8640000000000000) : parsedDate;
};

  useEffect(() => {
    fetchCases(); // Now it will run without warnings
  }, [fetchCases]);  // Add fetchCases as a dependency

  
  const createHighCourtCase = (document) => {
    const petitionerAdvocate = document.get("petitioner_advocate") || "Unknown Petitioner";
    const respondentAdvocate = document.get("respondent_advocate") || "Unknown Respondent";
    const caseStatus = document.get("case_status") || {};
  
    return {
      id: document.id,
      title: `${petitionerAdvocate} vs ${respondentAdvocate}`,  // Use the correct fields for petitioner and respondent
      nextDate: caseStatus["Next Hearing Date"] || "No Next Hearing Date",
      court: caseStatus["Coram"] || "Unknown Court",
    };
  };
  

  const createDistrictCourtCase = (document) => {
    const petitioners = document.get("Petitioners") || ["Unknown Petitioner"];
    const respondents = document.get("Respondents") || ["Unknown Respondent"];
    return {
        id: document.id,
        title: `${petitioners.join(", ")} vs ${respondents.join(", ")}`,
        nextDate: document.get("NextHearingDate") || "No Next Hearing Date",
        court: document.get("CourtNumberAndJudge") || "Unknown Court",
        petitionerAdvocate: Array.isArray(petitioners) ? petitioners : [petitioners],
        respondentAdvocate: Array.isArray(respondents) ? respondents : [respondents],
    };
};

  const createCATCase = (document) => {
    const petitioner = document.get("Petitioner(s)") || "Unknown Petitioner";
    const respondent = document.get("Respondent(s)") || "Unknown Respondent";
    const nextListingDate = document.get("Case Proceeding Details")?.[0]?.["Next Listing Date"] || "No Next Hearing Date";
    return {
      id: document.id,
      title: `${petitioner} vs ${respondent}`,
      nextDate: nextListingDate,
      court: document.get("Case Proceeding Details")?.[0]?.["Court Name"] || "Unknown Court",
    };
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = cases.filter(c => 
      c.title?.toLowerCase().includes(query.toLowerCase()) ||
      (typeof c.petitionerAdvocate === 'string' && c.petitionerAdvocate.toLowerCase().includes(query.toLowerCase())) ||
      (typeof c.respondentAdvocate === 'string' && c.respondentAdvocate.toLowerCase().includes(query.toLowerCase()))
    );
    setFilteredCases(filtered);
  };
  

  const handleCaseClick = (caseData) => {
    setSelectedCase(null); // Clear previous case while fetching new one
  
    if (isMobileView) {
      document.body.classList.add('hide-case-list'); // Add a class to hide case list on mobile
    }
  
    const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
  
    // Use Firestore's onSnapshot to listen to real-time updates for the selected case
    const unsubscribe = onSnapshot(caseDocRef, (caseDoc) => {
      if (caseDoc.exists()) {
        const fullCaseDetails = caseDoc.data();
  
        let caseDetailData = {
          id: caseData.id,
          caseType: fullCaseDetails.caseType || "Unknown Case Type",
          title: fullCaseDetails.CNRNumber || "Unknown Title",
          court: fullCaseDetails.CourtNumberAndJudge || "Unknown Court",
          nextDate: fullCaseDetails.NextHearingDate || "No Next Hearing Date",
          history: fullCaseDetails.CaseHistory || [],
          orders: fullCaseDetails.orders || [],
          notes: fullCaseDetails.notes || [],
          payments: fullCaseDetails.payments || [],
          toDos: fullCaseDetails.toDos || [],
          pendingInvoices: fullCaseDetails.pendingInvoices || [],
          petitionerAdvocate: fullCaseDetails.petitioner_advocate || "Unknown Petitioner",
          respondentAdvocate: fullCaseDetails.respondent_advocate || "Unknown Respondent",
          chatGptSuggestions: fullCaseDetails.ChatGPTSuggestions || "No suggestions available",
        };
  
        // Set the case details for rendering in CaseDetail component
        setSelectedCase(caseDetailData);
        if (isMobileView) {
          setIsMobileView(false);
        }
      } else {
        console.error('No such case!');
      }
    });
  
    return () => unsubscribe(); // Clean up the listener when the case changes
  };

  
  const handleBackToList = () => {
    setSelectedCase(null);
    if (isMobileView) {
      document.body.classList.remove('hide-case-list');
    }
  };
  
  return (
    <div className="dashboard-container">
      <div className={`case-list-container ${selectedCase && isMobileView ? 'hide-case-list' : ''}`}>
        <SearchBar value={searchQuery} onSearch={handleSearch} />
        {isLoading ? (
          <p>Loading cases...</p>
        ) : filteredCases.length === 0 ? (
          <p>No cases found</p>
        ) : (
          <div className="case-list">
            {filteredCases.map(c => (
              <CaseCard key={c.id} caseData={c} onClick={() => handleCaseClick(c)} />
            ))}
          </div>
        )}
      </div>
  
      {selectedCase && (
        <div className="case-detail-container full-screen">
          
            <button className="back-button" onClick={handleBackToList}>
              Back to Cases
            </button>
        
          <CaseDetail caseData={selectedCase} userId={userId} />
        </div>
      )}
    </div>
  );
}



function SearchBar({ value, onSearch }) {
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onSearch(e.target.value)}
      placeholder="Search cases..."
      style={{
        padding: '10px',
        margin: '20px 0',
        width: '100%',
        boxSizing: 'border-box'
      }}
    />
  );
}

function CaseCard({ caseData, onClick }) {
  return (
    <div 
      style={{
        padding: '20px',
        margin: '10px 0',
        border: '1px solid #ddd',
        borderRadius: '5px',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      {/* Common fields for all case types */}
      <h3>{caseData.title || "Unknown Title"}</h3>
      <p><strong>Court:</strong> {caseData.court || "Unknown Court"}</p>
      <p><strong>Next Hearing Date:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>

      {/* Specific details based on case type */}
      {caseData.caseType === "HighCourt" && (
        <div>
          <p><strong>High Court:</strong> {caseData.court}</p>
          <p><strong>Coram:</strong> {caseData.court || "N/A"}</p>
          <p><strong>Next Hearing:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>
        </div>
      )}

      {caseData.caseType === "DistrictCourt" && (
        <div>
          <p><strong>CNR Number:</strong> {caseData.title || "Unknown CNR"}</p>
          <p><strong>Court:</strong> {caseData.court || "N/A"}</p>
          <p><strong>Next Hearing:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>
        </div>
      )}

      {caseData.caseType === "CATCases" && (
        <div>
          <p><strong>Case Number:</strong> {caseData.title || "Unknown Case Number"}</p>
          <p><strong>Court:</strong> {caseData.court || "N/A"}</p>
          <p><strong>Next Listing Date:</strong> {caseData.nextDate || "No Next Listing Date"}</p>
        </div>
      )}
    </div>
  );
}


export default Dashboard;
