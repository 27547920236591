import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import Firebase config
import { collection, getDocs, addDoc } from 'firebase/firestore'; // Firestore functions
import { useUser } from '../UserContext'; // Import the useUser hook

const Team = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [team, setTeam] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', mobile: '' });
  const [loading, setLoading] = useState(true);

  // Fetch team members from Firestore
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const teamCollectionRef = collection(db, 'users', userId, 'team');
        const teamSnapshot = await getDocs(teamCollectionRef);
        const teamList = teamSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeam(teamList);
      } catch (error) {
        console.error('Error fetching team:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, []);

  // Handle adding new team members
  const addTeamMember = async () => {
    if (!newMember.name || !newMember.mobile) {
      alert('Please provide both name and mobile.');
      return;
    }

    try {
      const teamCollectionRef = collection(db, 'users', userId, 'team');
      const newMemberData = {
        name: newMember.name,
        mobile: newMember.mobile,
      };

      // Add the new member document to Firestore
      await addDoc(teamCollectionRef, newMemberData);

      // Update the local state to reflect the new member
      setTeam([...team, newMemberData]);
      setNewMember({ name: '', mobile: '' }); // Reset the form

      alert('Team member added successfully!');
    } catch (error) {
      console.error('Error adding team member:', error);
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Team Page</h2>

      {loading ? (
        <p>Loading team members...</p>
      ) : (
        <div>
          {team.length > 0 ? (
            <div>
              <h3>Current Team Members</h3>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {team.map((member, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}>
                    <strong>Name:</strong> {member.name} <br />
                    <strong>Mobile:</strong> {member.mobile}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p>No team members added yet.</p>
          )}
        </div>
      )}

      <div style={{ marginTop: '30px' }}>
        <h3>Add New Team Member</h3>
        <input
          type="text"
          placeholder="Name"
          value={newMember.name}
          onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
          style={{ padding: '10px', margin: '10px', width: '200px' }}
        />
        <input
          type="text"
          placeholder="Mobile"
          value={newMember.mobile}
          onChange={(e) => setNewMember({ ...newMember, mobile: e.target.value })}
          style={{ padding: '10px', margin: '10px', width: '200px' }}
        />
        <br />
        <button onClick={addTeamMember} style={{ padding: '10px 20px', marginTop: '10px' }}>
          Add Team Member
        </button>
      </div>
    </div>
  );
};

export default Team;
