import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout';
import './DistrictSelection.css'; // Updated Modern styling

function DistrictSelection({ userId, userData, setUserData }) {
  const [districts, setDistricts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { selectedStates } = userData;
  const step = 3; // Correct step number
  const totalSteps = 6;

  useEffect(() => {
    if (selectedStates && selectedStates.length > 0) {
      loadDistricts(selectedStates);
    } else {
      setErrorMessage('No states selected.');
      setIsLoading(false);
    }
  }, [selectedStates]);

  const loadDistricts = async (states) => {
    const districtMap = {};
    try {
      for (const state of states) {
        const snapshot = await getDocs(collection(db, 'districtstates', state, 'districts'));
        districtMap[state] = snapshot.docs.map((doc) => doc.id);
      }
      setDistricts(districtMap);
    } catch (error) {
      console.error('Error fetching districts:', error);
      setErrorMessage('Failed to load districts.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDistrictSelection = (state, district) => {
    const newSelectedDistricts = { ...userData.selectedDistricts };
    if (!newSelectedDistricts[state]) {
      newSelectedDistricts[state] = [];
    }
    if (newSelectedDistricts[state].includes(district)) {
      newSelectedDistricts[state] = newSelectedDistricts[state].filter((d) => d !== district);
    } else {
      newSelectedDistricts[state].push(district);
    }
    setUserData({ ...userData, selectedDistricts: newSelectedDistricts });
  };

  const handleNext = () => {
    navigate('/complex-selection', { state: { userId, userData } });
  };

  const handleBack = () => {
    navigate(-1); // Go to the previous page
  };

  return (
    <MultiStepFormLayout 
      step={step}
      totalSteps={totalSteps}
      onNext={handleNext}
      onBack={handleBack}
      isNextDisabled={Object.keys(userData.selectedDistricts).flat().length === 0}
      isLoading={isLoading}
      errorMessage={errorMessage}
    >
      {isLoading ? (
        <div>
          <p>Loading Districts...</p>
          <div className="progress"></div>
        </div>
      ) : errorMessage ? (
        <p className="error-message">Error: {errorMessage}</p>
      ) : (
        <div className="district-selection-page">
          {selectedStates.map((state) => (
            <div key={state}>
              <h3 className="state-heading">{state}</h3>
              <ul>
                {districts[state]?.map((district) => (
                  <li key={district} className="district-checkbox-container">
                    <span className="district-label">{district}</span>
                    <input
                      type="checkbox"
                      checked={userData.selectedDistricts[state]?.includes(district) || false}
                      onChange={() => toggleDistrictSelection(state, district)}
                      className="district-checkbox"
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </MultiStepFormLayout>
  );
}

export default DistrictSelection;
